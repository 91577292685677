$node-size: 220px;
$node-tail-size: 18px;
$node-action-size: 32px;
$node-action-offset: 10px;

.ml-auto {
  margin-left: auto;
}

.react-flow__node-drag-holder {
  visibility: hidden !important;
  * {
    visibility: hidden !important;
  }
}

.meta-connection {
  pointer-events: bounding-box;
  cursor: default;
  transition-property: opacity;
  transition-duration: 300ms;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0;

  &-left {
    left: -54px;
    top: calc(50% - 1px);
  }

  &-right {
    right: -53px;
  }

  &:hover {
    opacity: 1;
  }
}

.react-flow__node-and,
.react-flow__node-andOr {
  pointer-events: none;

  .react-flow__handle {
    visibility: hidden;
    pointer-events: none;
  }
}

.react-flow__node-default,
.react-flow__node-drag-holder,
.react-flow__node-end-state,
.react-flow__node-connection {
  border: none !important;
  width: $node-size + $node-tail-size + 4;
  height: 102px;
  padding: 0;
  margin: 0;

  &:has(.collapse-state-hidden) {
    visibility: hidden !important;
  }

  &:has(.collapse-state-ghost) {
    visibility: visible !important;
  }

  .react-flow__handle-right {
    right: 1px;
    top: 50%;
    pointer-events: none;
    visibility: hidden;
  }

  .connection-highlight {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: absolute;
    height: 17px;
    min-width: 31px;
    right: 15px;
    bottom: 13px;
    background-color: #00223A;
    color: white;

    svg {
      color: white;
      stroke: white;
      height: 12px;
      width: auto;
    }
  }

  .node-container {
    position: absolute;
    cursor: default;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: stretch;
    padding: 13px 26px 13px 13px;

    &.no-content {
      color: #636d73;
    }

    &.editing {
      .node-actions-container {
        pointer-events: none;
      }
    }

    &.root {
      .node-action-left,
      .node-action-up,
      .node-action-down {
        display: none;
      }
    }

    .node-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      color: #000;
      font-family: 'Usual', serif;
      word-break: break-word;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: left;
      white-space: nowrap;
      z-index: 1;

      .content-text {
        flex: 1 1 auto;
        align-self: stretch;
        overflow-y: auto;
        pointer-events: none;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        text-overflow: ellipsis;
      }

      .content-input {
        flex: 1 1 auto;
        align-self: stretch;
        outline: none;
        padding: 0;
        border: none;
        resize: none;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
      }

      .custom-row > * {
        font-weight: 400;
        border-radius: 4px;
      }

      .MuiChip-filled {
        border-radius: 4px;
      }
    }
  }

  .react-flow__handle-left {
    top: 50%;
    left: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .node-actions-container {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: -20px;
    bottom: 0;

    .node-action {
      z-index: 3;
      border: none;
      position: absolute;
      fill: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      gap: 8px;
      width: $node-action-size;
      height: $node-action-size;
      background-color: transparent;
      opacity: 0;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
      transition-property: opacity, top, left, right, bottom;
      cursor: pointer;

      svg{
        z-index: 4;
        position:relative;
      }

      &.node-action-left {
        transform: translate(0, -50%);
        left: 10px;
        top: 50%;
      }

      &.node-action-up {
        transform: translate(calc(-50% - $node-tail-size), 0);
        left: 50%;
        top: 20px;
      }

      &.node-action-right {
        transform: translate(calc(-50% - $node-tail-size), -50%);
        right: 20px;
        top: 50%;
      }

      &.node-action-down {
        transform: translate(calc(-50% - $node-tail-size), 0);
        bottom: 20px;
        left: 50%;
      }
    }

    &:hover {
      .node-action {
        opacity: 1;
      }

      .node-action-left {
        left: -$node-action-size - $node-action-offset;
        top: 50%;
      }

      .node-action-up {
        left: 50%;
        top: -$node-action-size - $node-action-offset;
      }

      .node-action-right {
        right: -$node-action-size - $node-action-offset - 20px;
        top: 50%;
      }

      .node-action-down {
        bottom: -$node-action-size - $node-action-offset;
        left: 50%;
      }
    }
  }
}

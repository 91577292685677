@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-Light.woff2) format('woff2'),
    url(assets/fonts/Usual-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-LightItalic.woff2) format('woff2'),
    url(assets/fonts/Usual-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-Regular.woff2) format('woff2'),
    url(assets/fonts/Usual-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-Italic.woff2) format('woff2'),
    url(assets/fonts/Usual-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-Medium.woff2) format('woff2'),
    url(assets/fonts/Usual-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-MediumItalic.woff2) format('woff2'),
    url(assets/fonts/Usual-MediumItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-Bold.woff2) format('woff2'),
    url(assets/fonts/Usual-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-BoldItalic.woff2) format('woff2'),
    url(assets/fonts/Usual-BoldItalic.woff) format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-ExtraBold.woff2) format('woff2'),
    url(assets/fonts/Usual-ExtraBold.woff) format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Usual';
  src: url(assets/fonts/Usual-ExtraBoldItalic.woff2) format('woff2'),
    url(assets/fonts/Usual-ExtraBoldItalic.woff) format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
